import '../scss/bundle.scss';
import * as anchorLinks from "./base/anchorLinks";
import * as detectElemsInViewport from "./base/detectElemsInViewport";
import * as detectors from "./base/detectors";
import * as FloatLabels from "./base/FloatLabels";
import * as header from "./base/header";
import * as linkPrefetch from "./base/linkPrefetch";
import * as viewportHeightCorrection from "./base/viewportHeightCorrection";
import * as lazyModuleLoader from "./lazyModuleLoader";

//------------------------------------------------------------------------------
// init
//------------------------------------------------------------------------------

// base
linkPrefetch.init();
detectors.init();
viewportHeightCorrection.init();
anchorLinks.init();
detectElemsInViewport.init();
header.init();
FloatLabels.init();

// modules
if(document.querySelectorAll(".kh--vc-contentslider").length>0)
{
  import('./modules/Contentslider').then(Contentslider => {
    Contentslider.init(document.querySelectorAll('.kh--vc-contentslider'));
  });
}

// lazy module loader
lazyModuleLoader.load();

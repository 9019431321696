import FloatLabels from "float-labels.js/src/float-labels";

//------------------------------------------------------------------------------
// init
//------------------------------------------------------------------------------
const init=() => {
  if(!!document.querySelector('.fluentform'))
  {

    document.querySelectorAll('.fluentform .ff-el-is-required').forEach((elem) => {
      if(!elem.parentElement.classList.contains('ff_list_buttons') && elem.parentElement.querySelector('.ff-el-form-control'))
      {
        elem.parentElement.querySelector('.ff-el-form-control').classList.add('required');
      }
    });

    new FloatLabels('.fluentform form', {
      customEvent:null,
      customLabel:null,
      customPlaceholder:null,
      exclude:'.no-label',
      inputRegex:/email|number|password|search|tel|text|url/,
      prefix:'fl-',
      prioritize:'label',
      requiredClass:'required',
      style:1,
      transform:'input, select, textarea',
    });
  }
}

//------------------------------------------------------------------------------
// exports
//------------------------------------------------------------------------------
export {
  init
};

import quicklink from "quicklink/dist/quicklink.js";
import {isLoggedIn} from '../config/util';
//------------------------------------------------------------------------------
// init
//------------------------------------------------------------------------------
const init=() => {
  if(!isLoggedIn)
  {
    quicklink.listen();
  }
}

//------------------------------------------------------------------------------
// exports
//------------------------------------------------------------------------------
export {
  init
};

import {isInViewport} from '../config/util';
//------------------------------------------------------------------------------
// init
//------------------------------------------------------------------------------
const init=() => {

  if(document.querySelectorAll('.kh--elem-viewport-check').length>0)
  {
    document.querySelectorAll('.kh--elem-viewport-check').forEach((elem) => {
      isInViewport(elem, (entry) => {
        if(entry.isIntersecting)
        {
          entry.target.classList.add('kh--is-visible');
        }
      });
    });
  }
}

//------------------------------------------------------------------------------
// exports
//------------------------------------------------------------------------------
export {
  init
};

import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import {body, html, pageHeader} from '../config/util';

//------------------------------------------------------------------------------
// init
//------------------------------------------------------------------------------
const init=() => {

  pageHeader.querySelectorAll('.w-nav-list').forEach((elem) => {
    elem.setAttribute('body-scroll-lock-ignore', '');
  });

  $('#page-header .w-nav-control').on('click', (event) => {
    if(event.target.closest('.w-nav-control').classList.contains('active'))
    {
      disableBodyScroll(html, {
        allowTouchMove:(elem) => {
          while(elem && elem !== body)
          {
            if(elem.getAttribute('body-scroll-lock-ignore') !== null)
            {
              return true;
            }

            elem=elem.parentElement;
          }
        }, reserveScrollBarGap:true,
      });
    }
    else
    {
      enableBodyScroll(html);
    }
  }, {
    passive:true,
  });
}

//------------------------------------------------------------------------------
// exports
//------------------------------------------------------------------------------
export {
  init
};

import {detect} from "detect-browser";
import {html} from "../config/util";

//------------------------------------------------------------------------------
// properties
//------------------------------------------------------------------------------
/** @type {boolean|*} */
export const isTouchCapable='ontouchstart' in window ||
  window.DocumentTouch && document instanceof window.DocumentTouch ||
  navigator.maxTouchPoints>0 ||
  window.navigator.msMaxTouchPoints>0;
/** @type {boolean} */
export const isIE11=!!window.MSInputMethodContext && !!document.documentMode;
/** @type {boolean} */
export const isSafari=/^((?!chrome|android).)*safari/i.test(navigator.userAgent);
/** @type {boolean} */
export const isMobile=window.matchMedia('(pointer:coarse)') && window.matchMedia('(pointer:coarse)').matches;

/** @type {number} */
const scrollDistance=100;

/** @type {string} */
const scrolledClass='scrolled';

/** @type {detect-browser} */
const browser=detect();

//------------------------------------------------------------------------------
// functions
//------------------------------------------------------------------------------
/**
 * @return {boolean}
 */
export const isScrolled=function(){
  return !!html?html.classList.contains(scrolledClass):true;
};

//------------------------------------------------------------------------------
// init
//------------------------------------------------------------------------------
export const init=() => {

  // js status
  html.className=html.className.replace('js--none', 'js');

  // operating system status
  if(browser)
  {
    if(browser.os === 'Mac OS')
    {
      html.classList.add('os--macos');
    }
  }

  // touchevents status
  // if(isTouchCapable)
  // {
  //   html.classList.add('touchevents');
  // }
  // else
  // {
  //   html.classList.add('touchevents--none');
  // }

  // scrolled status
  window.addEventListener('scroll', () => {
    if(document.documentElement.scrollTop>scrollDistance)
    {
      html.classList.add(scrolledClass);
    }
    else
    {
      html.classList.remove(scrolledClass);
    }
  }, {passive:true});
}

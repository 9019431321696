//------------------------------------------------------------------------------
// imports
//------------------------------------------------------------------------------
import {html} from "../config/util";

//------------------------------------------------------------------------------
// properties
//------------------------------------------------------------------------------

/**
 *
 * @type {string}
 */
const customViewportCorrectionVariable='vh';

//------------------------------------------------------------------------------
// functions
//------------------------------------------------------------------------------
/**
 *
 * @param doc
 * @returns {handleResize}
 */
const setViewportProperty=(doc) => {
  let prevClientHeight;
  let customVar='--'+(customViewportCorrectionVariable || 'vh');

  function handleResize()
  {
    let clientHeight=doc.clientHeight;

    if(clientHeight === prevClientHeight)
    {
      return;
    }

    requestAnimationFrame(function updateViewportHeight(){
      doc.style.setProperty(customVar, (clientHeight*0.01)+'px');
      prevClientHeight=clientHeight;
    });
  }

  handleResize();
  return handleResize;
}
//------------------------------------------------------------------------------
// init
//------------------------------------------------------------------------------
const init=() => {
  setViewportProperty(html);
  window.addEventListener('resize', setViewportProperty(html), {passive:true});
}

//------------------------------------------------------------------------------
// exports
//------------------------------------------------------------------------------
export {
  init
};

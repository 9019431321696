//------------------------------------------------------------------------------
// imports
//------------------------------------------------------------------------------
import {scrollTo, scrollToElement} from "../config/scroll";

//------------------------------------------------------------------------------
// functions
//------------------------------------------------------------------------------
/**
 * @param {HTMLLinkElement} anchor
 */
const addAnchorClickListener=(anchor) => {
  anchor.addEventListener('click', event => {
    event.preventDefault();
    let targetIdentifier=anchor.dataset.target?anchor.dataset.target:anchor.getAttribute('href').split('#')[1];
    let target=anchor.classList.contains('data-id')?document.querySelector(`[data-id="${targetIdentifier}"]`):document.getElementById(targetIdentifier);

    if(target || targetIdentifier === 'top')
    {
      if(!anchor.classList.contains('no-url-change'))
      {
        if(history.pushState)
        {
          history.pushState(null, null, '#'+targetIdentifier);
        }
        else
        {
          location.hash='#'+targetIdentifier;
        }
      }
      if(target)
      {
        scrollToElement(target, 30);
      }
      else
      {
        scrollTo(0, 30);
      }
    }
  }, {passive:false});
}

//------------------------------------------------------------------------------
// init
//------------------------------------------------------------------------------
const init=() => {
  if(!!document.querySelector('.anchorlink'))
  {
    document.querySelectorAll('.anchorlink').forEach(anchor => {
      addAnchorClickListener(anchor);
    });
  }
}

//------------------------------------------------------------------------------
// exports
//------------------------------------------------------------------------------
export {
  init, addAnchorClickListener
};
